<template>
  <component :is="tag" v-if="obItem && obItem.name" v-text="obItem.name" />
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { PriceList, PriceListData } from "@planetadeleste/vue-mc-gw";

@Component
export default class PriceListPreview extends Vue {
  @Prop([Number, String]) readonly priceListId!: number;
  @Prop(Object) readonly item!: PriceListData;
  @Prop({ type: String, default: "span" }) readonly tag!: string;

  obModel: PriceList = new PriceList();

  get obItem(): PriceListData {
    return this.item || this.obModel.attributes;
  }

  async mounted() {
    if (this.priceListId && !this.item) {
      this.obModel = new PriceList({ id: this.priceListId });
      await this.obModel.fetch();
    }
  }
}
</script>
