<template>
  <v-data-table
    v-if="references.length"
    :headers="headers"
    :items="references"
    dense
    hide-default-footer
  >
    <template #top>
      <v-toolbar flat>
        <v-toolbar-title v-text="$t('invoice.references')" />
      </v-toolbar>
    </template>

    <template #[`item.is_global`]="{ item }">
      <active-icon :active="item.is_global" />
    </template>

    <template #[`item.created_at`]="{ item }">
      <span v-if="item.invoice">
        {{ $dayjs(item.invoice.created_at).format("L LT") }}
      </span>
    </template>

    <template #[`item.balance_value`]="{ item }">
      <price-viewer
        v-if="item.invoice"
        :currency="item.invoice.currency"
        :label="true"
        :value="
          bIsDN ? item.invoice.total_price_value : item.invoice.balance_value
        "
        chip
      />
    </template>

    <template #[`item.order_number`]="{ item }">
      <template v-if="item.invoice">
        <span class="font-weight-bold" v-text="item.invoice.order_serial" />
        <span class="ml-2" v-text="item.invoice.order_number" />
      </template>
    </template>
  </v-data-table>
</template>

<script lang="ts">
import { Component, Mixins, Prop } from "vue-property-decorator";
import {
  InvoiceData,
  InvoiceMovementType,
  InvoiceReferenceData,
} from "@planetadeleste/vue-mc-gw";
import DataTableMixin, { DataTableHeader } from "@/mixins/DataTableMixin";
import { get } from "lodash";
import ActiveIcon from "@/components/common/ActiveIcon.vue";
import PriceViewer from "@/components/common/PriceViewer.vue";
import { number } from "mathjs";

@Component({
  components: { PriceViewer, ActiveIcon },
})
export default class InvoicePreviewReferences extends Mixins(DataTableMixin) {
  @Prop(Object) readonly item!: InvoiceData;

  get references(): InvoiceReferenceData[] {
    return get(this.item, "references", []);
  }

  get iMovementType(): number {
    return number(get(this.item, "invoice_movement_type.code", 0));
  }

  /**
   * Invoice movement type is DEBIT NOTE
   * @returns {boolean}
   */
  get bIsDN(): boolean {
    return this.iMovementType === InvoiceMovementType.CODE_DEBIT_NOTE;
  }

  mounted() {
    const arHeaders: DataTableHeader[] = [
      { text: "global", value: "is_global", sortable: false },
      { text: "reference.reason", value: "reason", sortable: false },
      { text: "date", value: "invoice.created_at", sortable: false },
      {
        text: "invoice.type",
        value: "invoice.invoice_type.name",
        sortable: false,
      },
      {
        text: "invoice.dgi.number",
        value: "order_number",
        sortable: false,
      },
      { text: "total.invoice", value: "balance_value", sortable: false },
    ];
    this.setDTHeaders(arHeaders);
  }
}
</script>
