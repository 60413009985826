<template>
  <v-row v-if="item" align="start" no-gutters>
    <invoice-preview-toolbar :item="item" />

    <v-col class="d-flex justify-space-between my-8" cols="12">
      <div class="text-h5" v-text="sSerial" />

      <div class="text-body-1 grey--text text--darken-1">
        <span v-text="sSignAt" />
      </div>
    </v-col>

    <v-col v-bind="obColData">
      <field-preview
        :label="$t('invoice.movement.type')"
        :value="sMovementType"
      />
    </v-col>

    <v-col v-if="signed && cfeName" v-bind="obColData">
      <field-preview
        :label="$t('invoice.type')"
        :value="`${cfeName} - ${$t(item.is_cash ? 'cash' : 'credit')}`"
      />
    </v-col>

    <v-col v-bind="obColData">
      <field-preview :label="$t('date')" :value="sCreatedAt" />
    </v-col>

    <v-col v-if="sDueDate" v-bind="obColData">
      <field-preview :label="$t('due.date')" :value="sDueDate" />
    </v-col>

    <v-col v-if="item.currency" v-bind="obColData">
      <field-preview :label="$t('currency')">
        <template #value>
          <currency-preview :item="item.currency" :size="20" />
        </template>
      </field-preview>
    </v-col>

    <v-col v-if="sRate" v-bind="obColData">
      <field-preview :label="$t('rate')" :value="sRate" />
    </v-col>

    <v-col v-bind="obColData">
      <field-preview :label="$t('pricelist')">
        <template #value>
          <price-list-preview
            :item="item.price_list"
            :price-list-id="item.price_list_id"
          />
        </template>
      </field-preview>
    </v-col>

    <v-col v-if="displayReferences" class="mb-4" cols="12">
      <sheet dense depressed light outlined>
        <invoice-preview-references :item="item" />
      </sheet>
    </v-col>

    <v-col cols="12">
      <v-divider />
      <invoice-preview-totals :item="item" />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { InvoiceData, InvoiceMovementType } from "@planetadeleste/vue-mc-gw";
import { get } from "lodash";
import FieldPreview from "@/components/common/FieldPreview.vue";
import dayjs from "dayjs";
import CurrencyPreview from "@/modules/currencies/components/CurrencyPreview.vue";
import PriceListPreview from "@/modules/pricelists/components/PriceListPreview.vue";
import InvoicePreviewToolbar from "@/modules/invoices/components/preview/InvoicePreviewToolbar.vue";
import InvoicePreviewTotals from "@/modules/invoices/components/preview/InvoicePreviewTotals.vue";
import InvoicePreviewReferences from "@/modules/invoices/components/preview/InvoicePreviewReferences.vue";
import { number } from "mathjs";

@Component({
  components: {
    InvoicePreviewReferences,
    InvoicePreviewTotals,
    InvoicePreviewToolbar,
    PriceListPreview,
    CurrencyPreview,
    FieldPreview,
  },
})
export default class InvoiceFullPreview extends Vue {
  @Prop(Object) readonly item!: InvoiceData;

  get signed(): boolean {
    return get(this.item, "is_signed", false);
  }

  get cfeName(): string | undefined {
    return get(this.item, "cfe_name");
  }

  get sMovementType() {
    return get(this.item, "invoice_movement_type.name");
  }

  get sSerial() {
    return `${this.item.order_serial} ${this.item.order_number}`;
  }

  get sSignAt() {
    const sDate = this.item.sign_at;

    return `${dayjs(sDate).format("L")} ${dayjs(sDate).format("HH:mm:ss")}`;
  }

  get sDueDate() {
    const sDate = this.item.due_date;

    return sDate ? dayjs(sDate).format("L") : null;
  }

  get sCreatedAt() {
    const sDate = this.item.created_at;

    return dayjs(sDate).format("L");
  }

  get sRate() {
    return get(this.item, "rate", 0);
  }

  get iMovementType(): number {
    return number(get(this.item, "invoice_movement_type.code", 0));
  }

  get displayReferences() {
    const arMovementTypeList = [
      InvoiceMovementType.CODE_REFOUND,
      InvoiceMovementType.CODE_DEBIT_NOTE,
      InvoiceMovementType.CODE_RECEIPT,
      InvoiceMovementType.CODE_DEBT,
      15,
    ];
    return (
      !!this.item.customer_id && arMovementTypeList.includes(this.iMovementType)
    );
  }

  get obColData() {
    return {
      cols: 12,
      sm: 6,
      lg: 4,
    };
  }
}
</script>
